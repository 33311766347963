.btn.btn-primary, a {
    transition: all .2s ease-in-out
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
}

/* Example media query for responsive adjustments */
@media (max-width: 768px) {
    .container, .container-fluid {
        padding: 0 15px;
        margin: 0 auto;
    }
}

body {
    /*padding-top: 3.5rem;*/
    margin: 0;
    padding: 0;
    font-family: Lato, sans-serif;
    color: #989898;
    background-color: #000000;
}

#navbarNav a{
    color: #ffffff;
}


.gradient-text {
    background: var(--vp-home-hero-name-background);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: var(--vp-home-hero-name-color);
}

:root {
    --vp-home-hero-name-color: transparent;
    --vp-home-hero-name-background: -webkit-linear-gradient(
            120deg,
            #bd34fe 30%,
            #41d1ff
    );
    --vp-home-hero-image-background-image: linear-gradient(
            -45deg,
            #bd34fe 50%,
            #47caff 50%
    );
    --vp-home-hero-image-filter: blur(44px);
}

.base-bg-color {
    background-color: #3aa8e1;
}

.orange-bg-color {
    background-color: #ff5f37;
}

.highlight-color{
    background-color: #46B3E2;
}

.darklight-color{
    background-color: #3aa8e1;
}

.hero-image {
    width: 100%;
    height: 28vh; /* Full height */
    /*background: url('../img/background-bright-sml.png') no-repeat center center;*/
    background-size: cover;
}

.rotated-image {
    display: block; /* Center the image horizontally if it is inside a div */
    margin: 20px auto; /* Add some margin for visual clarity */
    transform: rotate(-10deg); /* Rotate the image 10 degrees to the left */
    transition: transform 0.3s ease; /* Add transition for a smooth effect */
}

/* Optional: hover effect */
.rotated-image:hover {
    transform: rotate(0deg); /* Rotate back to 0 degrees on hover */
}

.card-gradient {
    /* background: linear-gradient(135deg, #ff7e5f, #feb47b); */
    background: radial-gradient(mediumpurple, purple, mediumpurple);
    color: white; /* Ensure the text is readable */
    /*background: url('../img/background.png') no-repeat center center;*/
    background-size: cover;
    border-color: limegreen;
    border-width: 1px;
}

.card-gradient .card-body {
    background: none; /* Ensure the body inherits the gradient background */
    min-height: 150px;
    max-height: 150px;
    height: 150px;
}

.container-rounded {
    border-radius: 20px;
}

/* Scroll to Top Button */
#scrollToTopBtn {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 80px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: #ff007b; /* Set a background color */
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 15px; /* Some padding */
    border-radius: 85%; /* Rounded corners */
    font-size: 38px; /* Increase font size */
    font-weight: bolder;
    width: 85px;
}

#scrollToTopBtn:hover {
    background-color: deeppink; /* Add a dark-grey background on hover */
}

.yellow-gradient {
    /*background: linear-gradient(90deg, #f94c3b 0%, #f94c3b 50%, #edc87e 100%);*/
    /*background-size: 200% 200%;*/
    background: linear-gradient(to right, #ff5f37, hotpink, #ffd988, #ff5f37);
    animation: gradient 15s linear infinite;
}

.yellow-gradient-text {
    background: linear-gradient(to right, #ff5f37, #ffd988, lightcoral, #ff5f37);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.vibrant-bg {
    background: linear-gradient(to right, hotpink, white, lightpink, hotpink);
}

.vibrant-bg2 {
    background: linear-gradient(to right, hotpink, white, lightgreen);
}

.vibrant-text {
    background: linear-gradient(to right, hotpink, white, lightgreen);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.vibrant-text-hero {
    /*background: linear-gradient(to right, hotpink, white, lightgreen);*/
    /*-webkit-background-clip: text;*/
    /*-webkit-text-fill-color: transparent;*/
    font-family: 'bold marker';
    border: 2px;
    border-color: black;
    font-size: 5em;
    color: white;
}

.blue-gradient{
    background: linear-gradient(360deg, #81F0FE, white, #46B3E3);
    animation: gradient 15s linear infinite;
}

.blue-gradient2 {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#91c4eb+0,489ade+1,266ec0+100 */
    background: radial-gradient(ellipse at center,  #91c4eb 0%,#489ade 1%,#266ec0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    background-size: 200% 200%;
    animation: gradient 15s linear infinite;
}

.dark-blue-gradient{
    background: linear-gradient(180deg, #1c3657, #91c4eb, #1c3657);
    animation: gradient 15s linear infinite;
}

.dark-blue-gradient2 {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#91c4eb+0,489ade+1,266ec0+100 */
    background: radial-gradient(ellipse at center,  #1c3657 0%,#489ade 1%,#266ec0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    background-size: 200% 200%;
    animation: gradient 15s linear infinite;
}



.radial-blue-gradient{
    background: radial-gradient(#81F0FE, white, #46B3E3, #81F0FE);
    animation: gradient 15s linear infinite;
}

.coral-gradient{
    background: linear-gradient(360deg, #ff5f37, lightcoral, #ff5f37);
    /*background: linear-gradient(360deg, #ff5f37, #ffd988, lightcoral, #ff5f37);*/
    animation: gradient 15s linear infinite;
}

.orange-gradient {
    background: linear-gradient(180deg, #ff5f37, #ffd988, lightcoral, #ff5f37);
    animation: gradient 15s linear infinite;
}

.flex-grow-1 {
    flex-grow: 1;
}

.bg-multi-color {
    background-color: #000;
    background-image: url('../img/background.png');
    background-repeat: repeat; /* This makes the background repeat */
    background-size: auto; /* Keeps the original size of the background image */
    margin: 0; /* Removes default margin */
    padding: 0; /* Removes default padding */
}

.bg-div {
    background-image: url('../img/background.png');
    /*background-repeat: repeat; !* This makes the background repeat *!*/
    /*background-size: auto; !* Keeps the original size of the background image *!*/
    /*width: 100%;*/
    /*height: 100vh; !* Full viewport height *!*/
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
}

.container-rounded {
    border-radius: 200px;
}

img {
    max-width: 100%
}

a {
    color: #f94c3b
}

a:hover {
    color: #f71f0a
}

h1 {
    font-size: 35px !important;
}

h2 {
    font-size: 20px !important;
}

h1, h2, h3, h4, h5, h6 {
    color: white;
    font-family: Raleway, sans-serif;
}

.socials i.fab {
    color: white;
    font-size: 1.5rem
}

.socials-large i.fab {
    color: white;
    font-size: 3rem
}

.btn.btn-primary {
    background-color: #f94c3b;
    border-color: #f94c3b
}

.btn.btn-primary:hover {
    background-color: #e11a07;
    border-color: #e11a07
}