.btn.btn-primary, a {
  transition: all .2s ease-in-out;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

@media (width <= 768px) {
  .container, .container-fluid {
    margin: 0 auto;
    padding: 0 15px;
  }
}

body {
  color: #989898;
  background-color: #000;
  margin: 0;
  padding: 0;
  font-family: Lato, sans-serif;
}

#navbarNav a {
  color: #fff;
}

.gradient-text {
  background: var(--vp-home-hero-name-background);
  -webkit-text-fill-color: var(--vp-home-hero-name-color);
  background-clip: text;
}

:root {
  --vp-home-hero-name-color: transparent;
  --vp-home-hero-name-background: -webkit-linear-gradient(120deg, #bd34fe 30%, #41d1ff);
  --vp-home-hero-image-background-image: linear-gradient(-45deg, #bd34fe 50%, #47caff 50%);
  --vp-home-hero-image-filter: blur(44px);
}

.base-bg-color {
  background-color: #3aa8e1;
}

.orange-bg-color {
  background-color: #ff5f37;
}

.highlight-color {
  background-color: #46b3e2;
}

.darklight-color {
  background-color: #3aa8e1;
}

.hero-image {
  background-size: cover;
  width: 100%;
  height: 28vh;
}

.rotated-image {
  margin: 20px auto;
  transition: transform .3s;
  display: block;
  transform: rotate(-10deg);
}

.rotated-image:hover {
  transform: rotate(0);
}

.card-gradient {
  color: #fff;
  background: radial-gradient(#9370db, purple, #9370db) 0 0 / cover;
  border-width: 1px;
  border-color: #32cd32;
}

.card-gradient .card-body {
  background: none;
  height: 150px;
  min-height: 150px;
  max-height: 150px;
}

#scrollToTopBtn {
  z-index: 99;
  color: #fff;
  cursor: pointer;
  background-color: #ff007b;
  border: none;
  border-radius: 85%;
  outline: none;
  width: 85px;
  padding: 15px;
  font-size: 38px;
  font-weight: bolder;
  display: none;
  position: fixed;
  bottom: 80px;
  right: 30px;
}

#scrollToTopBtn:hover {
  background-color: #ff1493;
}

.yellow-gradient {
  background: linear-gradient(to right, #ff5f37, #ff69b4, #ffd988, #ff5f37);
  animation: 15s linear infinite gradient;
}

.yellow-gradient-text {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(to right, #ff5f37, #ffd988, #f08080, #ff5f37);
  -webkit-background-clip: text;
}

.vibrant-bg {
  background: linear-gradient(to right, #ff69b4, #fff, #ffb6c1, #ff69b4);
}

.vibrant-bg2 {
  background: linear-gradient(to right, #ff69b4, #fff, #90ee90);
}

.vibrant-text {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(to right, #ff69b4, #fff, #90ee90);
  -webkit-background-clip: text;
}

.vibrant-text-hero {
  color: #fff;
  border: 2px #000;
  font-family: bold marker;
  font-size: 5em;
}

.blue-gradient {
  background: linear-gradient(360deg, #81f0fe, #fff, #46b3e3);
  animation: 15s linear infinite gradient;
}

.blue-gradient2 {
  background: radial-gradient(#91c4eb 0%, #489ade 1%, #266ec0 100%) 0 0 / 200% 200%;
  animation: 15s linear infinite gradient;
}

.dark-blue-gradient {
  background: linear-gradient(#1c3657, #91c4eb, #1c3657);
  animation: 15s linear infinite gradient;
}

.dark-blue-gradient2 {
  background: radial-gradient(#1c3657 0%, #489ade 1%, #266ec0 100%) 0 0 / 200% 200%;
  animation: 15s linear infinite gradient;
}

.radial-blue-gradient {
  background: radial-gradient(#81f0fe, #fff, #46b3e3, #81f0fe);
  animation: 15s linear infinite gradient;
}

.coral-gradient {
  background: linear-gradient(360deg, #ff5f37, #f08080, #ff5f37);
  animation: 15s linear infinite gradient;
}

.orange-gradient {
  background: linear-gradient(#ff5f37, #ffd988, #f08080, #ff5f37);
  animation: 15s linear infinite gradient;
}

.flex-grow-1 {
  flex-grow: 1;
}

.bg-multi-color {
  background-color: #000;
  background-image: url("background.1cbdca53.png");
  background-repeat: repeat;
  background-size: auto;
  margin: 0;
  padding: 0;
}

.bg-div {
  background-image: url("background.1cbdca53.png");
}

.container-rounded {
  border-radius: 200px;
}

img {
  max-width: 100%;
}

a {
  color: #f94c3b;
}

a:hover {
  color: #f71f0a;
}

h1 {
  font-size: 35px !important;
}

h2 {
  font-size: 20px !important;
}

h1, h2, h3, h4, h5, h6 {
  color: #fff;
  font-family: Raleway, sans-serif;
}

.socials i.fab {
  color: #fff;
  font-size: 1.5rem;
}

.socials-large i.fab {
  color: #fff;
  font-size: 3rem;
}

.btn.btn-primary {
  background-color: #f94c3b;
  border-color: #f94c3b;
}

.btn.btn-primary:hover {
  background-color: #e11a07;
  border-color: #e11a07;
}
/*# sourceMappingURL=index.42206a16.css.map */
